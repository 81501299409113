import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { environment } from 'src/environments/environment';


const poolData = {
  UserPoolId: environment.adminUserPoolId, // Your user pool id here
  ClientId: environment.adminClientId // Your client id here  
  // ClientId: '6arku41a1ig83m8oa1mco59an0' // Your client id here  
};

const userPool = new CognitoUserPool(poolData);
let adminPayload = new Object();


export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/maf-admin/admin-dashboard',
  //   title: 'Dashboard',
  //   type: 'link',
  //   icontype: 'media-1_album'
  // },
  {
    path: '/maf-admin/agents-list',
    title: 'Agent List',
    type: 'link',
    icontype: 'design_app'
  },
  {
    path: '/maf-admin/create-insurance-company',
    title: 'Create Insurance Company',
    type: 'link',
    icontype: 'objects_diamond'
  },
  {
    path: '/maf-admin/insurance-company-list',
    title: 'Insurance Companies List',
    type: 'link',
    icontype: 'business_chart-pie-36'
  },
  {
    path: '/maf-admin/agent-policies-list',
    title: 'Agent Policies List',
    type: 'link',
    icontype: 'media-1_album'
  },
  {
    path: '/maf-admin/roles-list',
    title: 'Roles',
    type: 'link',
    icontype: 'design_app'
  },
  // {
  //   path: '/maf-admin/permissions-list',
  //   title: 'Permissions',
  //   type: 'link',
  //   icontype: 'design_app'
  // },
  {
    path: '/maf-admin/manageusers-list',
    title: 'Manage Users',
    type: 'link',
    icontype: 'objects_diamond'
  },
  {
    path: '/maf-admin/policy-payment-mangager',
    title: 'commission Statement',
    type: 'link',
    icontype: 'business_chart-pie-36'
  },
  {
    path: '/maf-admin/Testing_Levels_Structure',
    title: 'Testing The Levels',
    type: 'link',
    icontype: 'objects_diamond'
  },
  {
    path: '/maf-admin/agent_promotions',
    title: 'Agent Promotions',
    type: 'link',
    icontype: 'media-1_album'
  },
  {
    path: '/maf-admin/agents-charge-back',
    title: 'Charge Back',
    type: 'link',
    icontype: 'design_app'
  },
  {
    path: '/maf-admin/create-policy',
    title: 'Create Policy',
    type: 'link',
    icontype: 'design_app'
  }
];


@Injectable()
export class AdminauthorizationService {
  cognitoUser: any;
  messageSource: any;
  currentMessage: any;
  allroutes: any;
  modeOfWorking : any;
  allRoutesOfAdmin:any;
  policiesPermissionJson:any;

  constructor(private cookieService: CookieService) {
    // this.messageSource = new BehaviorSubject(this.cookieService.get('F024rHalogdimndbfEMN'));
    // this.currentMessage = this.messageSource.asObservable();
    // console.log('this.messageSource', this.messageSource)
    // console.log('this.currentMessage', this.currentMessage)
    this.modeOfWorking = environment.envmode
    this.allRoutesOfAdmin = ROUTES;
    this.policiesPermissionJson = [];
    // ROUTES.map(x=>{
    //   // console.log("here in admin ",x)
    //   if(x.type =="dashboard"){
    //     x['PermissionCode'] = ['dashboard']
    //   }
    //   if(x.type =="viewAgents")
    //   {
    //     x['PermissionCode'] = ['viewagentslist','viewagentdetails','viewlevels','approveagent','rejectagent','appointagent','unappointagent','downloadlicense','downloadEandO','viewagentspromotion']; 
    //     // console.log("nbnbn",x['PermissionCode'])
    //   }
    //   if(x.type =="insuranceCompany")
    //   {
    //     x['PermissionCode'] = ['addinsurance'];
    //   }
    //   if(x.type =="allInsuranceCompanies")
    //   {
    //     x['PermissionCode'] = ['viewinsurancelist'];
    //   }
    //   if(x.type =="addPolicy")
    //   {
    //     x['PermissionCode'] = ['addpolicy'];
    //   }
    //   if(x.type =="viewPolicies")
    //   {
    //     x['PermissionCode'] = ['viewpolicylist','filterpolicy','updatedeliverydate'];
    //   }
    //   if(x.type =="manageRoles")
    //   {
    //     x['PermissionCode'] = ['createrole','updaterole'];
    //   }
    //   if(x.type =="manageUsers")
    //   {
    //     x['PermissionCode'] = ['createuser'];
    //   }
    //   if(x.type =="commissionStatement")
    //   {
    //     x['PermissionCode'] = ['viewcommssionstatement','downloadcsv','uploadcsv'];
    //   }
    // })
  }

  register(email, password) {
    const attributeList = [];
    return Observable.create(observer => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          // console.log("signUp error", err);
          observer.error(err);
        }
        else {
          this.cognitoUser = result.user;
          // console.log("signUp success", result);
          observer.next(result);
          observer.complete();
        }
      });
    });
  }

  confirmAuthCode(code) {
    const user = {
      Username: this.cognitoUser.username,
      Pool: userPool
    };
    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          // console.log(err);
          observer.error(err);
        }
        // console.log("confirmAuthCode() success", result);
        observer.next(result);
        observer.complete();
      });
    });
  }

  signIn(email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    return Observable.create(observer => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          observer.next(result);
          observer.complete();
        },
        onFailure: function (err) {
          // console.log(err);
          observer.error(err);
        },
      });
    });
  }

  isLoggedIn() {
    return userPool.getCurrentUser() != null;
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return userPool.getCurrentUser();
  }

  getFalseOnIntruder() {
    // let adminUser = localStorage.getItem('CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.LastAuthUser');
    // let adminaccessToken = localStorage.getItem('CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.f2d9cb41-9fd6-44b7-a268-16d6f27f4210.accessToken');
    // let idToken = localStorage.getItem('CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.f2d9cb41-9fd6-44b7-a268-16d6f27f4210.idToken');
    // let refreshToken = localStorage.getItem('CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.f2d9cb41-9fd6-44b7-a268-16d6f27f4210.refreshToken');

    // if(adminUser && adminaccessToken && refreshToken)
    // {
    return true;
    // }
    // else{
    //   return false;
    // }

  }

  getSubApplicationbasedUser(){
    let sidebar = [];
    let key = 'maf@3423614276';
    // console.log('auth guard');
    let adminLoginData:any = CryptoJS.AES.decrypt(this.cookieService.get('Ma6343uhsusfsfSsgsfs2r').toString().replace(/ /g,'+'),key);
     console.log('auth guard',adminLoginData);
    if(adminLoginData['sigBytes']>0)
    {
      // console.log('xxxxxxxx',JSON.parse(adminLoginData.toString(CryptoJS.enc.Utf8))['data']);
      // let permissionsList = adminLoginData['sigBytes']>0 ? JSON.parse(adminLoginData.toString(CryptoJS.enc.Utf8))['data']['MainData']['RoleInfo']['permissions'] : []; 
      let roleName = adminLoginData['sigBytes']>0 ? JSON.parse(adminLoginData.toString(CryptoJS.enc.Utf8))['data']['MainData']['RoleInfo']['roleName'] : 'super admin';
      // console.log('auth permissionsList',permissionsList);
      // console.log('auth guard',permissionsList);
    }
  }


  getFalseRouteChange() {
    // return true;
    let sidebar = [];
    let key = 'maf@3423614276';
    let adminLoginData:any = CryptoJS.AES.decrypt(this.cookieService.get('Ma6343uhsusfsfSsgsfs2r').toString().replace(/ /g,'+'),key);
    // console.log('xxxxxxxxxxxxxxxxx',adminLoginData['sigBytes'])
    if(adminLoginData['sigBytes']>0)
    { 
      let roleName = adminLoginData['sigBytes']>0? JSON.parse(adminLoginData.toString(CryptoJS.enc.Utf8))['data']['MainData']['RoleInfo']['roleName'] : 'super admin';
      if (roleName.toLowerCase() !== 'super admin') {
      let permissionsList = adminLoginData['sigBytes']>0 ? JSON.parse(adminLoginData.toString(CryptoJS.enc.Utf8))['data']['MainData']['RoleInfo']['permissions'] : [];
        this.policiesPermissionJson = JSON.parse(adminLoginData.toString(CryptoJS.enc.Utf8))['data']['MainData']['permissionList'];
        let allrout = [];
        // console.log('roleName roleName',roleName);
        // console.log('roleName roleName',permissionsList);
        // ROUTES.map(x => {
        //   for (let i = 0; i < permissionsList.length; i++) {
        //     if (x['PermissionCode'] == permissionsList[i]['PermissionCode']) {
        //       allrout.push(x.path.split('/')[x.path.split('/').length - 1]);
        //     }
        //     if(this.modeOfWorking == 'production')
        //     {
        //       let index = allrout.findIndex(link => link === 'Testing_Levels_Structure');
        //       allrout.splice(index, 1);
        //     }else{
        //       allrout = allrout;
        //     }
        //   }
        // })

        // console.log('ROUTES ROUTES',ROUTES);

        ROUTES.map(x=>{
          permissionsList.map(p=>
          {
            if(x['PermissionCode'] == p.PermissionCode)
            {
              allrout.push(x.path);
            }
            if(this.modeOfWorking == 'production')
            {
              let index = allrout.findIndex(link => link === 'Testing_Levels_Structure');
              allrout.splice(index, 1);
            }else{
              allrout = allrout;
            }
          })
        })

        allrout.push("admin-login")

        // console.log('allrout allrout',allrout);
        // allrout.push("tseitng_parpeso_nut_usuable_n_eyist_relrapp")
        this.allroutes = allrout
        let urlarr = location.href.split('/')
        let url = urlarr[urlarr.length - 1]
        console.log('url url', url);
        // if (this.allroutes.indexOf(url) != -1 || undefined) {
        //   return true
        // } else {
        //   return false
        // }
        return true
      }
      else {

        let getIndex = ROUTES.findIndex(x => x['PermissionCode'] === 'testingthelevels')
        ROUTES.splice(getIndex, 1);
        let urlarrY = location.href.split('/')
        let urlY = urlarrY[urlarrY.length - 1]

        if(this.modeOfWorking == 'production')
        {
          if(urlY==='Testing_Levels_Structure')
          {
            return false;
          }else{
            return true;
          }
        }else{
           return true;
        }
      }
    }else{
      return false;
    }
  }

  logOut() {
    // console.log('i am')
    this.getAuthenticatedUser().signOut();
    this.cookieService.delete('A878478DdMUsalofsgRU');
    this.cookieService.delete('Ma6343uhsusfsfSsgsfs2r');
    this.cookieService.delete('F024rHalogdimndbfEMN');
    this.cookieService.deleteAll();
    this.cognitoUser = null;
    localStorage.clear();
    window.location.reload();
  }
}
