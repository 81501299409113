import { Injectable } from '@angular/core';
import { AuthenticationDetails,CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';

import {Router} from '@angular/router';
// import AWS from 'aws-sdk/global';

const agentpoolData = {
  UserPoolId: environment.agentUserPoolId, // Your user pool id here
  ClientId: environment.agentClientId // Your client id here  
};

const agentuserPool = new CognitoUserPool(agentpoolData);

const adminpoolData = {
  UserPoolId: environment.adminUserPoolId, // Your user pool id here
  ClientId: environment.adminClientId // Your client id here  
  // ClientId: '6arku41a1ig83m8oa1mco59an0' // Your client id here  
};

const adminuserPool = new CognitoUserPool(adminpoolData);

@Injectable({
  providedIn: 'root'
})
export class TotalauthorizationService {
cognitoUser: any;

  constructor(private cookieService: CookieService,private myRoute: Router) { }

  isAgentLoggedIn() {
    return agentuserPool.getCurrentUser() != null;
  }

  isAdminLoggedIn() {
    return adminuserPool.getCurrentUser() != null;
  }
  isAgentRedirect()
  {
  	this.myRoute.navigate(["/maf-agent/agent-dashboard"]);
  	return true;
  }
}
