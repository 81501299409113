import { Injectable } from '@angular/core';
import { CanLoad,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from 
'@angular/router';
import { Observable } from 'rxjs/Observable';
import { TotalauthorizationService } from './customservices/totalauthorization.service';
import {Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TotalauthGuard implements CanActivate {
  constructor(private auth: TotalauthorizationService,
    private myRoute: Router){
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  	console.log('shyam total auth auard')
  	console.log('shyam total auth auard agent',this.auth.isAgentLoggedIn())
  	console.log('shyam total auth auard admin',this.auth.isAdminLoggedIn())
  	console.log('shyam total auth auard admin',this.auth.isAgentRedirect())
  	console.log('shyam total auth auard',location.href)
    if(this.auth.isAgentLoggedIn()==true){
    	console.log('i am inside agent');
      // return true;
      if(location.href.indexOf('maf-agent')==-1)
      {
      	console.log('i am inside agent routing');
      	location.href = '/maf-agent/agent-dashboard';
      	// return this.auth.isAgentRedirect();
      	return true;
      }else if(location.href.indexOf('maf-agent')!=-1 && location.href.indexOf('agent-login')!=-1)
      {
      	console.log('i am inside agent routing');
      	location.href = '/maf-agent/agent-dashboard';
      	// return this.auth.isAgentRedirect();
      	return true;
      }
      return true;
    }else if(this.auth.isAdminLoggedIn()==true){
    	console.log('i am inside agent');
      // return true;
      if(location.href.indexOf('maf-admin')==-1)
      {
      	console.log('i am inside admin routing');
      	location.href = '/maf-admin/admin-dashboard';
      	// return this.auth.isAgentRedirect();
      	return true;
      }else if(location.href.indexOf('maf-admin')!=-1 && location.href.indexOf('admin-login')!=-1)
      {
      	console.log('i am inside admin routing');
      	location.href = '/maf-admin/admin-dashboard';
      	// return this.auth.isAgentRedirect();
      	return true;
	    }
	   }
	    else{
	      return false;
	    }
    }
  }
