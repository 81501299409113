import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

import { TotalauthGuard } from './totalauth.guard';


export let browserRefresh = false;
const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      { path: '', loadChildren: './layout/layout.module#LayoutModule'},
      { path: '', loadChildren: './calculator/calculator.module#CalculatorModule'},
      { path: 'maf-admin', loadChildren: './adminlayout/adminlayout.module#AdminlayoutModule'},
      { path: 'maf-agent', loadChildren: './agentlayout/agentlayout.module#AgentlayoutModule'},
      { path: 'page-not-found', loadChildren: './not-found/not-found.module#NotFoundModule'},
      { path: '**',redirectTo: 'page-not-found'}
    ]
  }
]; 


@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
  })],
 // useHash: true
  exports: [RouterModule]
})
export class AppRoutingModule { }
