import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthorizationService } from './customservices/authorization.service';
import { AdminauthorizationService } from './customservices/adminauthorization.service';

import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthhomeGuard implements CanActivate {
  href: any;
  cokieAgent: any;
  urlString: string;

  constructor(
    private auth: AuthorizationService,
    private authAgent: AdminauthorizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let authenticatedUser = this.auth.getAuthenticatedUser();
    let intruderLocalChange = this.auth.getFalseOnIntruder();
    let agentId = localStorage.getItem('CognitoIdentityServiceProvider.4gkgpcug955h6kfapt4n61ojkr.LastAuthUser');


    console.log('intruderLocalChange', intruderLocalChange, agentId, authenticatedUser)
    if (authenticatedUser != null && intruderLocalChange == true) {
      // this.router.navigateByUrl('maf-agent/agent-profile');
      // return true;
    } else {
      // return true;
    }
    return true
  }
}
