import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AdminauthorizationService } from './customservices/adminauthorization.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AdminauthorizationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      // handle any redirects if a user isn't authenticated
      let authenticatedUser = this.auth.getAuthenticatedUser();
      let intruderLocalChange = this.auth.getFalseOnIntruder();
      let intruderRouteGuard = this.auth.getFalseRouteChange();


      let subApplicationbasedUser = this.auth.getSubApplicationbasedUser();

      // console.log('intruderRouteGuard',intruderRouteGuard);

      // console.log('intruderLocalChange intruderLocalChange',intruderLocalChange);
      if (authenticatedUser == null) {
        this.router.navigate(['/maf-admin']);
        return false;
      }
      else if (intruderLocalChange != true) {
      this.router.navigate(['/maf-admin']);
      return false;
      }else if(intruderRouteGuard==false && authenticatedUser!=null)
      {
        this.router.navigate(['/maf-admin']);
        return false;
      }
      else{
      	return true;
      }
  }
}
