export const environment = {
  production: true,
  baseHref: '/',
  environmentName:"Production",
  envmode:'production',
  agentApiUrl: 'https://9c2w3v3kb2.execute-api.us-west-1.amazonaws.com/prod/',
  adminApiUrl:'https://mdium785u3.execute-api.us-west-1.amazonaws.com/prod/',
  apiUrl : 'https://q4ph7hsmg6.execute-api.us-east-1.amazonaws.com/realstage/',
  // adminPoolIdToken:'CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.f2d9cb41-9fd6-44b7-a268-16d6f27f4210.idToken',

  s3AssectUrl:'https://s3.amazonaws.com/mafserverless/',
  agentUserPoolId:'us-west-2_19XTgiSnt',
  agentClientId:'ihle3f2m921sd2fs3vfjpmij7',
  adminUserPoolId:'us-west-2_Fog5Hg8xZ',
  adminClientId:'4moav7l647mifd5gr20dop4nj3',
  paypalMode:'Production',
  paypalEnvironment:'production',
  paypalInitialAmount:125.00,
  // paypalInitialAmount:0.01,
  agentCommissionPercentage:80,
  agentAdvancesPercentage:75,
  agentAsearnedPercentage:25,
  cognitoAgentRegion:'us-east-1',
  //shyam live
  // paypalClientId:'AROvqSLp53CTFZ5f9ENX7Sfa3Nqz78E4haw3JMPOecZ6p6chH9MHzbj-Ncujd1P8yn4sTGSMNiBviVN7',

  //prnjit live
  paypalClientId:'AVCZRYnGXgKILgF9Nd2eizYkHYF2afypw-bMqlmWNQDV8E0e8_gEp3AEUBg4p6KBVIqLSkt3aAOPlzDn',

  LzeroL:'Associate',
  LzeroS:'RA',
  LoneL:'Senior Associate',
  LoneS:'SA',
  LtwoL:'District Leader',
  LtwoS:'DL',
  LthreeL:'Senior District Leader',
  LthreeS:'SDL',
  LfourL:'Regional Director',
  LfourS:'RD',
  LfiveL:'Senior Regional Director',
  LfiveS:'SRD',
  LsixL:'Executive Regional Director',
  LsixS:'ERD',
  LsevenL:'Executive National Director',
  LsevenS:'END',

  levelObject:{
     "ComanyName": "MAF",
     "Level0": {
       "RoleFullName": "Associate",
       "RoleName": "RA"
     },
     "Level1": {
       "RoleFullName": "Senior Associate",
       "RoleName": "SA"
     },
     "Level2": {
       "RoleFullName": "District Leader",
       "RoleName": "DL"
     },
     "Level3": {
       "RoleFullName": "Senior District Leader",
       "RoleName": "SDL"
     },
     "Level4": {
       "RoleFullName": "Regional Director",
       "RoleName": "RD"
     },
     "Level5": {
       "RoleFullName": "Senior Regional Director",
       "RoleName": "SRD"
     },
     "Level6": {
       "RoleFullName": "Executive Regional Director",
       "RoleName": "ERD"
     },
     "Level7": {
       "RoleFullName": "Executive National Director",
       "RoleName": "END"
     }
    }
};