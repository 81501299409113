import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'maxagent';
    public ngOnInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            let bases = this.document.getElementsByTagName('base');
            if (bases.length > 0) {
                bases[0].setAttribute('href', environment.baseHref);
            }
        }
    }
    constructor(@Inject(PLATFORM_ID) private platformId: any, @Inject(DOCUMENT) private document: any) { }
}
