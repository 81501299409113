import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'app-root' }),
        AppModule,
        TypeaheadModule.forRoot(),
        BsDatepickerModule.forRoot(),
        MDBBootstrapModule,
    ]
})
export class AppBrowserModule { }
