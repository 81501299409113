// import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AgentService } from '../agent.service';
import { UsCountriesService } from './us-countries.service';

import { AuthorizationService } from './customservices/authorization.service';
import { AdminauthorizationService } from './customservices/adminauthorization.service';
import { TotalauthorizationService } from './customservices/totalauthorization.service';

import { EncrDecrService } from './customservices/cryptoEncryptDecrypt.service';
// import { OnlytypenumberDirective } from './onlytypenumber.directive';

import { CookieService } from 'ngx-cookie-service';

import { AuthGuard } from './auth.guard';

import { AuthagentGuard } from './authagent.guard';

import { TotalauthGuard } from './totalauth.guard';

import { AuthhomeGuard } from './authhome.guard';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BsModalModule } from 'ng2-bs3-modal';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { TypeaheadConfig } from 'ngx-bootstrap/typeahead';
// import { TagInputModule } from 'ngx-chips';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxTypeaheadModule } from 'ngx-typeahead';

import { TypeaheadModule } from 'ngx-bootstrap';
import { PagerService } from './customservices/pager.service';
// import { AppPasswordDirective } from './apppassword.directive';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { HighchartsChartModule } from 'highcharts-angular';

import { ModalModule } from 'ngx-bootstrap/modal';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { Ng2OdometerModule } from 'ng2-odometer';

// echarts
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    AppComponent,
    // AppPasswordDirective,
    // OnlytypenumberDirective
  ],
  imports: [
    CommonModule,
    // NgtUniversalModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    PdfViewerModule,
    AppRoutingModule,
    HttpClientModule,
    BsModalModule,
    // TagInputModule
    DeferLoadModule,
    NgSelectModule,
    NgxTypeaheadModule,
    NgxGraphModule,
    NgxChartsModule,
    HighchartsChartModule,
    MDBBootstrapModule.forRoot(),
    Ng2OdometerModule.forRoot(),
    NgxEchartsModule,
  ],
  providers: [PagerService, AgentService, AdminauthorizationService, TotalauthorizationService,CookieService,
    AuthorizationService, AuthGuard, AuthagentGuard, AuthhomeGuard,TotalauthGuard,UsCountriesService,EncrDecrService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,

  ]
})
export class AppModule { }
