import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthorizationService } from './customservices/authorization.service';

import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthagentGuard implements CanActivate {
  href: any;
  cokieAgent: any;
  urlString: string;

  constructor(
    private auth: AuthorizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let authenticatedUser = this.auth.getAuthenticatedUser();
    let intruderLocalChange = this.auth.getFalseOnIntruder();
    let intruderRouteGuard = this.auth.getFalseRouteChange();

    // let agentId = localStorage.getItem('CognitoIdentityServiceProvider.4gkgpcug955h6kfapt4n61ojkr.LastAuthUser');

    if (authenticatedUser == null) {
      // redirect the user
      this.cookieService.delete('AXRGBSYEQWATNLKFSYT');
      this.cookieService.delete('AHJSKIDGTERCDHDFCSKDHDGDT');
      // this.cookieService.delete('THJSIDGTFRCDHAFCSKDHDGDTPR');
      this.router.navigateByUrl('maf-agent/agent-login');
      return false;
    } else if (intruderLocalChange != true) {

      this.cookieService.delete('AXRGBSYEQWATNLKFSYT');
      this.cookieService.delete('AHJSKIDGTERCDHDFCSKDHDGDT');
      // this.cookieService.delete('THJSIDGTFRCDHAFCSKDHDGDTPR');
      this.router.navigateByUrl('maf-agent/agent-login');
      return false;
    }else if(intruderRouteGuard==false && authenticatedUser!=null)
    {
      this.router.navigate(['/maf-agent']);
      return false;
    } else {
      return true;
    }
  }
}
