import { Injectable } from '@angular/core';
import { AuthenticationDetails,CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';

// import AWS from 'aws-sdk/global';

const poolData = {
  UserPoolId: environment.agentUserPoolId, // Your user pool id here
  ClientId: environment.agentClientId // Your client id here  
};

const userPool = new CognitoUserPool(poolData);
AWS.config.region =  environment.cognitoAgentRegion;

// Inactive keys
AWS.config.accessKeyId = 'AKIAYWE5IJM2WCVRJJEZ';
AWS.config.secretAccessKey = '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';
// End of Inactive keys

const cognitoisp = new AWS.CognitoIdentityServiceProvider();

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/maf-agent/agent-dashboard',
  //   title: 'Dashboard',
  //   type: 'link',
  //   icontype: 'media-1_album'
  // },
  
  {
    path: '/maf-agent/agent-profile',
    title: 'Agent Profile',
    type: 'link',
    icontype: 'design_app'
  },
  // {
  //   path: '/maf-agent/error-ommission',
  //   title: 'E & O',
  //   type: 'link',
  //   icontype: 'objects_diamond'
  // },
  {
    path: '/maf-agent/submit-a-case',
    title: 'Submit A Case',
    type: 'link',
    icontype: 'media-1_album'
  },
  // {
  //   path: '/maf-agent/log-insurance',
  //   title: 'Log Insurance',
  //   type: 'link',
  //   icontype: 'media-1_album'
  // },
  {
    path: '/maf-agent/view-insurance',
    title: 'View Insurance',
    type: 'link',
    icontype: 'business_chart-pie-36'
  },
  {
    path: '/maf-agent/reports',
    title: 'Reports',
    type: 'link',
    icontype: 'media-1_album'
  },
  {
    path: '/maf-agent/level-hierarchy',
    title: 'Upline Leaders',
    type: 'link',
    icontype: 'design_app'
  },
  {
    path: '/maf-agent/agent-tasks',
    title: 'Agent Promotions',
    type: 'link',
    icontype: 'objects_diamond'
  },
  {
    path: '/maf-agent/agents-commissions',
    title: 'Agent Commissions',
    type: 'link',
    icontype: 'business_chart-pie-36'
  },
  {
    path: '/maf-agent/analytics',
    title: 'Analytics',
    type: 'link',
    icontype: 'media-1_album'
  },
  {
    path: '/maf-agent/charge-back',
    title: 'Charge Back',
    type: 'link',
    icontype: 'objects_diamond'
  }
];



@Injectable()
export class AuthorizationService {
  cognitoUser: any;
  allroutfinal: any;
  agentbreadcrumbsidebarlist:any;
  constructor(private cookieService: CookieService) { 
        this.agentbreadcrumbsidebarlist = ROUTES;
  }

  register(email, password, agentid) {

    var attributeList = [];

    let dataAgentId = {
      Name: 'custom:agentId',
      Value: agentid
    };
    let attributeAgentId = new CognitoUserAttribute(dataAgentId)



    attributeList.push(attributeAgentId);

    attributeList.push(new CognitoUserAttribute({ 'Name': 'email', 'Value': email }));

    return Observable.create(observer => {
      userPool.signUp(agentid, password, attributeList, null, (err, result) => {
        if (err) {
          // console.log('signUp error', err);
          observer.error(err);
        }
        else {
          this.cognitoUser = result.user;
          // console.log('signUp success', result);
          observer.next(result);
          observer.complete();
        }
      });
    });
  }




  // confirmAuthCode(code) {
  //   const user = {
  //     Username : this.cognitoUser.username,
  //     Pool : userPool
  //   };
  //   return Observable.create(observer => {
  //     const cognitoUser = new CognitoUser(user);
  //     cognitoUser.confirmRegistration(code, true, function(err, result) {
  //       if (err) {
  //         console.log(err);
  //         observer.error(err);
  //       }
  //       console.log('confirmAuthCode() success', result);
  //       observer.next(result);
  //       observer.complete();
  //     });
  //   });
  // }

  signIn(agentid, password) {

    // console.log('poolDatapoolData',poolData)

    const authenticationData = {
      Username: agentid,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: agentid,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return Observable.create(observer => {

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {

          // console.log('aaaaaaaaaaaaagent',result);
          observer.next(result);
          observer.complete();
        },
        onFailure: function (err) {
          // console.log(err);
          observer.error(err);
        },
      });
    });
  }

  isLoggedIn() {
    return userPool.getCurrentUser() != null;
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }

  getFalseOnIntruder() {

    let agentId = localStorage.getItem('agentLoginID');
    let key = 'maf@3423614276'
    let decryptedId:any = CryptoJS.AES.decrypt(this.cookieService.get('AXRGBSYEQWATNLKFSYT').toString().replace(/ /g,'+'),key);
    // console.log('decryptedIddecryptedId',decryptedId)
    // console.log('decryptedIddecryptedId',typeof decryptedId)
    // console.log('decryptedIddecryptedId',decryptedId['sigBytes'])
    let gatData = decryptedId['sigBytes']!=0 ? JSON.parse(decryptedId.toString(CryptoJS.enc.Utf8)) : null;
    let aid = gatData != null ? gatData['agentId'] : null;

    // console.log('xxxxxxxxxxxxxx',aid,agentId)
    if (agentId != null && aid != null && agentId == aid) {
      return true;
    }
    else {
      return false;
    }
  }

  getAgentRefreshToken()
  {
    // let params = {
    //   AuthFlow: localStorage.getItem('CognitoIdentityServiceProvider.4gkgpcug955h6kfapt4n61ojkr.AAA24.refreshToken'),
    //   ClientId:environment.agentClientId,
    //   UserPoolId: environment.agentUserPoolId,
    //   AuthParameters: {
    //     'REFRESH_TOKEN': localStorage.getItem('CognitoIdentityServiceProvider.4gkgpcug955h6kfapt4n61ojkr.AAA24.refreshToken')
    //   }
    // }

    // cognitoisp.adminInitiateAuth(params, function(err, data) {
    //   // if (err){console.log('errrrrrrrrrrrrrrr',err)} // an error occurred
    //   else{console.log('dddddddddddddddddata',data)};           // successful response
    // });
    return true;
  }

  getFalseRouteChange()
  {
    let sidebar = [];
    let key = 'maf@3423614276';
    let sidebarlinks:any = CryptoJS.AES.decrypt(this.cookieService.get('SxydudtvAgkoiluiSB').toString().replace(/ /g,'+'),key);
    let actualBoolean = sidebarlinks['sigBytes']!=0 ? JSON.parse(sidebarlinks.toString(CryptoJS.enc.Utf8)) : null;

    let allrout = [];
    let allroutfinal = [];
    allrout.push("agent-login")
    allrout.push("change-password")
    allrout.push("agent-tasks")
    allrout.push("advances-report")
    allrout.push("promoter-acknowledged")
    allrout.push("promotee-acknowledged")
    allrout.push("analytics")
    allrout.push("charge-back")

    if(actualBoolean && actualBoolean['agentSideBar'] == false)
    {
      ROUTES.map(x => {
        if(x.path.split('/')[x.path.split('/').length - 1] == 'agent-profile')
        {
         allrout.push(x.path.split('/')[x.path.split('/').length - 1]);
        }
      })
      // this.allroutfinal = allrout;
    }else if(actualBoolean && actualBoolean['agentSideBar'] == true){
      ROUTES.map(x => {
         allrout.push(x.path.split('/')[x.path.split('/').length - 1]);
      })
    }

    this.allroutfinal = allrout;

    // console.log('side bar agent linking',sidebar);
    // console.log('side bar agent linking',allrout);
    // console.log('side bar agent linking',this.allroutfinal);
    let urlarr = location.href.split('/')
    let url = urlarr[urlarr.length - 1]
    // console.log('url url', url);
    if (this.allroutfinal.indexOf(url) != -1 || this.allroutfinal.indexOf('advances-report')!=-1) {
      return true
    } else {
      return false
    }
  }

  logOut() {
    this.getAuthenticatedUser().signOut();
    this.cookieService.delete('AHJSKIDGTERCDHDFCSKDHDGDT');
    this.cookieService.delete('THJSIDGTFRCDHAFCSKDHDGDTPR');
    this.cookieService.delete('AXRGBSYEQWATNLKFSYT');
    this.cookieService.deleteAll();
    this.cognitoUser = null;
    localStorage.clear();
  }
}
