import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpHandler, HttpRequest, HttpEvent,HttpResponse,HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { AuthenticationDetails,CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';

import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import { throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

const AWS = require('aws-sdk')
const poolData = {
  UserPoolId: environment.agentUserPoolId, // Your user pool id here
  ClientId: environment.agentClientId // Your client id here  
};

// const userPool = new CognitoUserPool(poolData);
AWS.config.region =  environment.cognitoAgentRegion.toString();

// Inactive keys
AWS.config.accessKeyId = 'AKIAYWE5IJM2WCVRJJEZ';
AWS.config.secretAccessKey = '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';
// End of Inactive keys

const cognitoisp = new AWS.CognitoIdentityServiceProvider({region:'us-east-1'});
// AWS.config.update({region: 'us-east-1'});


// console.log('location',location.href);
// console.log('localStorage',localStorage.getItem('adminIdToken'))

// let httpHeaders = new HttpHeaders({
//     'Accept' :'application/json',
//     'x-api-key':'hKvfly14s67JH2jfSzOv539HPPdK9X7L9okRuAnu'   //api key wont change
//   });



//CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.f2d9cb41-9fd6-44b7-a268-16d6f27f4210.idToken
//CognitoIdentityServiceProvider.6arku41a1ig83m8oa1mco59an0.f2d9cb41-9fd6-44b7-a268-16d6f27f4210.idToken
@Injectable({
  providedIn: 'root'
})

// @Injectable()
export class AgentService{

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  public data;
  public httpOptions:any;
  public httpHeaders:any;
  // public environmentAdminApi = 'https://q4ph7hsmg6.execute-api.us-east-1.amazonaws.com/realstage/';

  environmentName:any = '';
  environmentAdminApi:any = ''
  environmentAgentApi:any = ''
  apiUrl:any = ''

  constructor(private http: HttpClient, private router : Router) {
    this.environmentName = environment.environmentName;
    this.environmentAdminApi = environment.adminApiUrl;
    this.environmentAgentApi = environment.agentApiUrl;
    this.apiUrl = environment.apiUrl;

    // this.environmentAdminApi = this.apiUrl;
    // this.environmentAgentApi = this.apiUrl;

    this.htmlHeaderInitializer();



      //  var params = {
      //   AuthFlow: 'REFRESH_TOKEN',
      //   // ClientId:environment.adminClientId,
      //   ClientId:'5eb3rfhgjnr3k70brn1iupvi2b',
      //   UserPoolId: 'us-east-1_t3UkKhDG6',
      //   AuthParameters: {
      //     'REFRESH_TOKEN': localStorage.getItem('adminRefreshToken')
      //   }
      // }

      // cognitoisp.adminInitiateAuth(params, function(err, data) {
      //   console.log('ccccccccccccccccc data',data);
      //   console.log('errrrrrrrrrrrrrrrrrrrrrrr data',err);
      //   if (err) console.log(err, err.stack); // an error occurred
      //   else     console.log(data);           // successful response
      // });

// var data = { UserPoolId: 'us-east-1_t3UkKhDG6', ClientId: '5eb3rfhgjnr3k70brn1iupvi2b', region:'us-east-1'};
// const userPool = new CognitoUserPool(data);
// var cognitoUser = userPool.getCurrentUser();
// if (cognitoUser != null) {
//   cognitoUser.getSession(function(err, session) {
//     if (err) {
//       alert(err);
//       return;
//     }
//     // Get refresh token before refreshing session
//     let refresh_token = session.getRefreshToken();
//     // if (AWS.config.credentials.needsRefresh()) {
//       cognitoUser.refreshSession(refresh_token, (err, session) => {
//         if (err) {
//           console.log(err);
//         } else {
//           AWS.config.credentials.params.Logins[
//             'cognito-idp.'+data.region+'.amazonaws.com/data.UserPoolId'
//           ] = session.getIdToken().getJwtToken();
//           AWS.config.credentials.refresh(err => {
//             if (err) {
//               console.log(err);
//             } else {
//               console.log('TOKEN SUCCESSFULLY UPDATED');
//             }
//           });
//         }
//       });
//     // }
//   });
// }


    // if(localStorage.getItem('adminIdToken')!=null && location.href.indexOf('maf-admin')!=-1)
    // {

    //    var params = {
    //     AuthFlow: 'REFRESH_TOKEN',
    //     ClientId:environment.adminClientId,
    //     UserPoolId: environment.agentUserPoolId.toString(),
    //     AuthParameters: {
    //       'REFRESH_TOKEN': localStorage.getItem('adminRefreshToken')
    //     }
    //   }

    //   cognitoisp.adminInitiateAuth(params, function(err, data) {
    //     if (err) console.log(err, err.stack); // an error occurred
    //     else     console.log(data);           // successful response
    //   });
    // }
    // if(localStorage.getItem('agentIdToken')!=null && location.href.indexOf('maf-agent')!=-1)
    // {
    //    var params = {
    //     AuthFlow: 'REFRESH_TOKEN',
    //     ClientId:environment.agentClientId,
    //     UserPoolId: environment.agentUserPoolId.toString(),
    //     AuthParameters: {
    //       'REFRESH_TOKEN': localStorage.getItem('agentRefreshToken')
    //     }
    //   }

    //   cognitoisp.adminInitiateAuth(params, function(err, data) {
    //     if (err) console.log(err, err.stack); // an error occurred
    //     else     console.log(data);           // successful response
    //   });
    // }

    // console.log('game of thrones',this.htmlHeaderInitializer());
  }
      

htmlHeaderInitializer()
{
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders = this.httpHeaders.append('Accept', 'application/json');
    if(localStorage.getItem('adminIdToken')!=null && location.href.indexOf('maf-admin')!=-1)
    {
      // console.log('admin header',localStorage.getItem('adminIdToken'))
      this.httpHeaders = this.httpHeaders.append('Authorization', localStorage.getItem('adminIdToken'));
    }
    else if(localStorage.getItem('agentIdToken')!=null && location.href.indexOf('maf-agent')!=-1)
    {
      // console.log('agent header',localStorage.getItem('agentIdToken'))
      this.httpHeaders = this.httpHeaders.append('Authorization', localStorage.getItem('agentIdToken'));
    }
    else
    {
      // console.log('not login header')
      this.httpHeaders = this.httpHeaders.append('x-api-key', 'hKvfly14s67JH2jfSzOv539HPPdK9X7L9okRuAnu')
    }

    // console.log('constructer header',this.httpHeaders);
    this.httpOptions = {
      headers: this.httpHeaders
    };


    // console.log('i am the API function exist every time',this.httpOptions)




    return this.httpOptions;
}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.log(error.status,location.href)
      if(error.status==0)
      {
          swal({
           title:"Sorry",
           text: "Session time out or internet issue,Please try again!",
           type: "error",
           timer: 3000,
           showConfirmButton: false
         }).catch(swal.noop).then(() =>{
           if(location.href.indexOf('maf-agent')!=-1)
           {
             location.href = '/maf-agent/agent-login';
           }
           if(location.href.indexOf('maf-admin')!=-1)
           {
             location.href = '/maf-admin/admin-login';
           }
         })
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  /** Register Service Call */

//MAF-AGENT-PRE-LOGIN

  registerPost(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'agent/registration', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //register servce call for the local docu-sigup

    registerPostLocalDocu(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'agent/registration-local', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Payment SAVE LOGIC;

  postPayment(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'agent/register-email', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  sendEnquiry(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'agent/contactus', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  ///agent password restting code

  resettingAgentPassword(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'agent/reset-password', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


      // Forgotpassword
    agentforgotpasswordPost(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'agent/forgot-password', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    //Update docusign status;

    updateDocuStatus(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'agent/docusignup-success', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    //email id checking
    
    emailIdChecking(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'agent/check-email', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    //sponser id checking
    
    sponserIdChecking(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'agent/check-sponserid', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

//MAF-AGENT-POST-LOGIN


  getAgentProfile(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/agent-profile', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

//MAF agent levels commission

  getAgentLevelCommission(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/policies-list/get-agent-commission-list', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  //Agent to do tasks list getAgentTasksToDo

  getAgentTasksToDo(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/get-agent-tasks', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

    //Agent to do tasks list getAgentCompletedTasks

  getAgentCompletedTasks(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/get-agent-completed-tasks', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }



    //Agent to do tasks list getAgentPromoteeTasksToDo

  getAgentPromoteeTasksToDo(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/get-agent-tasks-as-promotee', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }




    //Agent to do tasks list getAgentPromoteeCompletedTasks

  getAgentPromoteeCompletedTasks(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/get-agent-completed-tasks-as-promotee', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }




 //agent promoter doc sign initializer

  agentPromoterDocInitializer(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/promoter-docusign-initiate', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


   //agent promoteee doc sign initializer

  agentPromoteeeDocInitializer(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/promotee-docusign-initiate', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }



  //agent promoter docusign success agentPromoterDocSuccess

  agentPromoterDocSuccess(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/promoter-docusign-success', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


    //agent promoter docusign success agentPromoteeeDocSuccess

  agentPromoteeeDocSuccess(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/promotee-docusign-success', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  //Agent to do tasks list getAgentTasksComplete

  // getAgentTasksComplete(data): Observable<any> {
  //   return this.http.post(this.environmentAgentApi + 'maf-agent/promotions/get-agent-tasks', data, this.htmlHeaderInitializer())
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  //changing Agent Password
  changingAgentPassword(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/change-password', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  writePolicy(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/write-policy', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  getPolocies(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/policies-list', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadProfilePic(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/upload-agent-profile-pic', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadAgentLicence(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/upload-agent-licence', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadAgentEandO(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/upload-agent-eando', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  EandOPostPayment(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/monthly-insurance-payment/agent-advance-month-payment', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

// TODO : get the list
  EandOAgentPaymentList(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/monthly-insurance-payment/agent-payment-list', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  getAllInsOnlog(agentId): Observable<any> {
    return this.http.get(this.environmentAgentApi + 'maf-agent/insurance-company-list/' + agentId, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


      // Agent Dashboard Get Agent Policy Graph

    getAgentPolicyGraph(id): Observable<any> {
      return this.http.get(this.environmentAgentApi + 'maf-agent/dashboard/policy-graph/' + id, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }


  getAgentPolicyGraphPost(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/dashboard/policy-graph', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }



  ///getting the list of sponser chain

    getAgentsListOfSponserChain(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/get-sponser-chain', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


    //GetAgentPolicyGraph



    //get all agents levels
  // getLevelAgentinAgentsDetails(data): Observable<any> {
  //   return this.http.post(this.environmentAgentApi + 'maf-agent/view-level',data, this.htmlHeaderInitializer())
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }
  getAgentHirarchyLevelsAgent(data): Observable<any> {
    return this.http.post(this.environmentAgentApi + 'maf-agent/view-level',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

    // Get Agent capping amount

    getAgentCappingAmount(id): Observable<any> {
      return this.http.get(this.environmentAgentApi + 'maf-agent/dashboard/capping-amount/' + id, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    // Get Agent Policy Cycles

    getAgentPolicyCycles(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/policy-cycles', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    getAgentPolicyAsEarned(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/policies-list/get-agent-asearned-payment', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    //Get Agent Policy data along with policy holder details

    getAgentPolicyDetails(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/policies-list/get-agent-policy-details', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }    

    // Get Agent Policies By Cycles

    getAgentPoliciesByCycles(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/policy-cycles/policies', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

    //Get Agent Policies By Cycle By Date

    getAgentPoliciesByCyclesByDate(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/advances-report', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    } 
    getTotalteampointsInlast3month(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/get-team-points', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    } 

    //Get Agent Policy AsEarned Date

    getAgentPoliciesAsEarnedDate(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/asearned-report', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

  //get charge back amount
    getAgentChargeBackDetails(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/chargeback', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }


//MAF-ADMIN-PRE-LOGIN

  //admin reset password

  resettingAdminPassword(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'admin/reset-password', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

//write admin policy

  writeAdminPolicy(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + 'maf-admin/policy/create-policy', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


    //get all details list of data
   getCreatePolicyDetailsAdmin(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/policy/get-policy-inputs', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  // Forgotpassword
  forgotpasswordPost(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'admin/forgot-password', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

//MAF-ADMIN-POST-LOGIN


  // Get All Agents

  getAllAgent(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/agents-section', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
  //Get
  getAllRejectedAgent(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/get-rejected-agent-list', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
  // Agent Details

  getAgentDetails(agentId): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/agent-by-id/' + agentId, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  //agent levels details

  getLevelAgentDetails(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/get-agent-level',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }



    getAgentListOfSponserChainAdminside(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/get-sponser-chain', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //policy amount distribution levels details

  getLevelPolicyExploringDetails(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/policy/get-policy-commission',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  // admin to update policy with delivery date
  
  updateDeliveryDate(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + 'maf-admin/policy/update-policy',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
  //Update Agent Current Status 
  updateAgentCurrentStatus(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + '/maf-admin/update-agent-current-status',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //Update Agent License Status
  updateAgentStatus(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + '/maf-admin/update-agent-license',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //Update Agent Address 
  updateAgentAddress(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + '/maf-admin/update-agent-address',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //Update Agent Preferred Name
  updatePreferredName(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + '/maf-admin/update-agent-prefername',data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
    
  // Agent accepted

  onAgentAccepted(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/accept-the-agent', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  onRejectEmail(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/reject-the-agent', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPolociesFilterById(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/get-policy-data-by-id', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  getAllPolociesFilterByPolicyId(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/get-policy-data-by-id', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  getAllAgentChargeBacks(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/chargeback',data,this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }





  //Test level updata 

  agentLevelChangeInFrontEnd(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/temp/promote-agent', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //get the agent promotions completed list in admin getAgentPromotionsCompletedInAdmin
  getAgentPromotionsCompletedInAdmin(): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/promotions/get-completed-promotions',{a:1}, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPolocies(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/all-policies-list', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
  getAllEandO(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/eando/get-eando-list', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  onAgentAssign(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/associate-agent', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  onAgentDissociative(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/disassociate-agent', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //   onAgentAssign(data): Observable<any> {
  //   return this.http.post(this.environmentAdminApi + 'maf-admin/assign-agent-insurance-company', data, this.htmlHeaderInitializer())
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  // onAgentDissociative(data): Observable<any> {
  //   return this.http.post(this.environmentAdminApi + 'maf-admin/dissociate-agent', data, this.htmlHeaderInitializer())
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  createInsCompany(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/insurance-company', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  getAllInsCompanyDetails(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/insurance-company', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
  // Finace Analyst API Calling

  // Create Permissions POST API Calling
  createPermissions(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/permission/create-permission', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }
  // Get Permissions

  getPermissionList(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/permission', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  //admin Change password

  changingAdminPassword(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/change-password', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  // Create Roles POST API Calling

  createAdminRoles(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/role/create-role', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get Permissions

  getRolesList(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/role', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateAdminRoles(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/role/update-role', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  //Testing app
  //get list of all agents and their levels]

  getAllAgentsAndTheirLevels(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/temp/get-agent-levels', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //pdf testring

    getDocusignsamplepdfload(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/docusign/viewiaa',data,this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }


  getAdminUserList(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/user', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  //Admin user
  createTheAdminUser(data): Observable<any> {
    return this.http.post(this.environmentAdminApi + 'maf-admin/user/create-user', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Admin user Deatisl Getting APIs

  requestAdminDetails(email): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/user/' + email, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  uploadCsvDataPost(data): Observable<any> {
    console.log(data);
    return this.http.post(this.environmentAdminApi + 'maf-admin/save-policies-csv-upload', data, this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
  }

    // get policies in cycles

  getPolociesOnCycles(): Observable<any> {
    return this.http.get(this.environmentAdminApi + 'maf-admin/policy-cycles', this.htmlHeaderInitializer())
      .pipe(
        catchError(this.handleError)
      );
    }
    getPendingPolociesOnCycles(): Observable<any> {
      return this.http.get(this.environmentAdminApi + 'maf-admin/policy-cycles/get-pending-advances', this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
      }
    // get policy data on cycle choose

    getPolociesDataOnCycleChoose(data): Observable<any> {
      return this.http.post(this.environmentAdminApi + 'maf-admin/policy-cycles/policies', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    getPendingPolociesDataOnCycleChoose(data): Observable<any> {
      return this.http.post(this.environmentAdminApi + 'maf-admin/policy-cycles/policies/get-pending-advances-by-cycle', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    //Get Admin Policy data along with policy holder details

    getAdminPolicyDetails(data): Observable<any> {
      return this.http.post(this.environmentAdminApi + 'maf-admin/policy/get-admin-policy-details', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    onChangeEmailid(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/agent-profile/update-emailid', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    onChangeAddress(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/agent-profile/update-address', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    getAllNotification(): Observable<any> {
      return this.http.get(this.environmentAdminApi + 'maf-admin/admin-get-reminder', this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    updateNewDeliverydate(data): Observable<any> {
      return this.http.post(this.environmentAdminApi + 'maf-admin/policy/edit-deliverydate', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    onChangePreferredName(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/agent-profile/update-prefername', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }
    onChangeLiscence(data): Observable<any> {
      return this.http.post(this.environmentAgentApi + 'maf-agent/agent-profile/update-license', data, this.htmlHeaderInitializer())
        .pipe(
          catchError(this.handleError)
        );
    }

}

